import React, { useRef, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import uploadDocIcon from "../../assets/Dashboard/Main/upload-document.png";
import CircularProgress from "@mui/joy/CircularProgress";
import { MdOutlineCancel } from "react-icons/md";
import axios from "../../libs/axios";
import { toast, ToastContainer } from "react-toastify";

const UploadPopup = ({ show, setShow, setDocuments }) => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  const fileRef = useRef(null);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState([]);
  const [error, setError] = useState("");

  const closePopup = (e) => {
    e.preventDefault();

    e.stopPropagation();
    setFile({});
    setTitle("");
    setShow(false);
  };
  const handleUploadFile = (e) => {
    e.stopPropagation();
    fileRef.current.click();
  };

  const handleStoreFile = (e) => {
    e.stopPropagation();
    setLoading(true);
    setTimeout(() => {
      setFile(e.target.files);
      setLoading(false);
    }, 1500);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setFile(droppedFiles);
    }
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();

    setFile({});
  };

  const handleDocumentSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const title = document.getElementById("title").value;

    const file = fileRef.current.files[0];

    if (!file || !title) {
      toast.warning("Both title and file are required");
      return;
    }

    setError("");
    setLoading(true);

    const formData = new FormData();
    formData.append("document", file);
    formData.append("title", title);

    try {
      const response = await axios.post(
        `/api/document/upload/${title}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response) {
        try {
          const docsRes = await axios.get("/api/document");
          setDocuments(docsRes.data.data);
        } catch (error) {
          console.log("Error fetching documents:", error);
        }
      }

      if (response.data.success === true || response.data.success === "true") {
        toast.success(response.data.message);
      } else {
      }
    } catch (err) {
      if (err.response) {
        toast.warning("File contains unsupported content");
      } else {
        toast.message("No response data in error");
      }
      setError("File upload failed. Please try again.");
    } finally {
      fileRef.current.value = null;

      setLoading(false);
      setTitle("");
      setFile({});
      setShow(false);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={`fixed top-0 left-0 z-20 w-full h-full ${
          show ? "block" : "hidden"
        } bg-[rgba(0,0,0,0.3)] text-white font-roboto`}
      >
        <div
          className={`m-auto p-4 absolute z-20 min-h-fit xl:w-[38vw] w-[80%] md:[60%] lg:w-[40%] opacity-100 text-center bg-white rounded-lg ${
            isMobile
              ? "transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              : "top-[28%] left-[38%]"
          }`}
        >
          <form onSubmit={handleDocumentSubmit}>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className=" p-2   border-[#A1A1A1] border  outline-none rounded text-[#A1A1A1]  w-full"
              placeholder="Add Title*"
              required
            />
            <div
              className=""
              onDrop={handleDrop}
              onDragOver={(event) => event.preventDefault()}
            >
              <div
                onClick={handleUploadFile}
                className=" mt-2   pt-3 flex flex-col justify-center items-center rounded  border-[#A1A1A1]/50"
                style={{
                  padding: "10px",
                  backgroundImage:
                    "linear-gradient(to right, #A1A1A1 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to bottom, #A1A1A1 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to left, #A1A1A1 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to top, #A1A1A1 50%, rgba(255, 255, 255, 0) 0%)",
                  backgroundSize: "10px 2px, 2px 10px, 10px 2px, 2px 10px",
                  backgroundPosition: "top, right, bottom, left",
                  backgroundRepeat: "repeat-x, repeat-y, repeat-x, repeat-y",
                }}
              >
                <input
                  onChange={handleStoreFile}
                  // className="w-[50%]"
                  id="fileInput"
                  type="file"
                  ref={fileRef}
                  accept=".doc,.txt"
                  hidden
                />
                {/* <img src="" alt=""> */}

                <img src={uploadDocIcon} alt={uploadDocIcon} />
                <p className=" text-[14px] mb-0  text-[#A1A1A1]">
                  Drag & Drop or{" "}
                  <span className="text-[#3A3AF4]">choose file</span> to upload
                  <br />
                  .dot or .txt
                </p>
              </div>
            </div>
            <div className="w-[100%] h-5 mt-3">
              {loading ? (
                <div className="align-middle">
                  <CircularProgress color="neutral" size="sm" value={30} />
                </div>
              ) : file && file.length > 0 ? (
                <div className="text-gray-600 text-start">
                  <div className="flex items-center gap-3">
                    {file[0].name}
                    <div className="" onClick={handleRemoveFile}>
                      <MdOutlineCancel size={20} />
                    </div>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className=" flex justify-end   gap-3 mt-3">
              <button
                className="text-[#000000] py-[3px] px-[12px] bg-[#F3F4F6] rounded shadow-sm border"
                onClick={closePopup}
              >
                Cancel
              </button>
              <button
                type="submit"
                className=" py-[3px] px-[14px] bg-[#3A3AF4] rounded text-[#FFFFFF] shadow-sm"
              >
                Done
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
