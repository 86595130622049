import React, { useEffect, useState } from "react";
import { Editor } from "primereact/editor";
import "./Editor.css";
import { Img } from "@chakra-ui/react";
import BoldIcon from "../../../assets/Editor/BoldIcon.png"
import LinkIcon from "../../../assets/Editor/LinkIcon.png"
import ItalicIcon from "../../../assets/Editor/ItalicIcon.png"
import HeadingIcon from "../../../assets/Editor/HeadingIcon.png"
import UnderLineIcon from "../../../assets/Editor/UnderLineIcon.png"
// import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import Skeleton from '@mui/material/Skeleton';
export default function BasicDemo({ newDocument, newSetDocument, setTitle, editorValue, setEditorValue, isMobile,setLoadingInner }) {
    const [text, setText] = useState('');
    const editorRef = React.useRef(null);
    const [loading, setLoading] = useState(true); // Add a loading state
    useEffect(() => {
        setTimeout(() => {
            setLoading(false); // Set loading to false after a delay
        }, 2000);
        // Customizing button text for H1 and H2
        let H1Button = document.querySelector('.ql-header[value="1"]');
        if (H1Button) {
            H1Button.innerHTML = `<img 
            src="${HeadingIcon}" 
            style="height: 24px; width: 24px;" alt="H1 Icon" />`;
        }
    }, []);

    const handleFormat = (format) => {
        if (editorRef.current && editorRef.current.getQuill) {
            const quill = editorRef.current.getQuill();
            if (format === 'bold') quill.format('bold', true);
            if (format === 'italic') quill.format('italic', true);
            if (format === 'underline') quill.format('underline', true);
            if (format === 'header') quill.format('header', 1);
            if (format === 'link') quill.format('link', 'https://example.com'); // Example link
        }
    };

    const header = (
        <span className="ql-formats" style={{ display: 'flex', alignItems: 'center', gap: "5px", height: "51px", borderBottom: "1px solid #DDDDDD" }}>
            {loading ? <Skeleton variant="square" width={40} height={40} animation="wave" /> : <button className="ql-bold" style={{ height: "34px", width: "34px" }} onClick={() => handleFormat('bold')}>
                <Img style={{ height: "24px", width: "24px" }} src={BoldIcon} />
            </button>}
            {loading ? <Skeleton variant="square" width={40} height={40} animation="wave" /> : <button className="ql-link" style={{ height: "34px", width: "34px" }} onClick={() => handleFormat('link')}>
                <Img style={{ height: "24px", width: "24px" }} src={LinkIcon} />
            </button>
            }
            {loading ? <Skeleton variant="square" width={40} height={40} animation="wave" /> : <button className="ql-italic" style={{ height: "34px", width: "34px" }} onClick={() => handleFormat('italic')}>
                <Img style={{ height: "24px", width: "24px" }} src={ItalicIcon} />
            </button>
            }
            {loading ? <Skeleton variant="square" width={40} height={40} animation="wave" /> : <button className="ql-header" value="1" style={{ height: "34px", width: "34px" }} onClick={() => handleFormat('header')}>
                <Img style={{ height: "24px", width: "24px" }} src={HeadingIcon} />
            </button>
            }
            {loading ? <Skeleton variant="square" width={40} height={40} animation="wave" /> : <button className="ql-underline" style={{ height: "34px", width: "34px" }} onClick={() => handleFormat('underline')}>
                <Img style={{ height: "24px", width: "24px" }} src={UnderLineIcon} />
            </button>
            }

        </span>
    );
    const stripHtml = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };
    return (
        <div>
            {/* Toolbar with full-width */}
            <div style={{ width: "100%" }}>
                {header}
            </div>
            {/* Centered content editor */}
            <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{                
                    width: isMobile ? "100%" : "100%",  
                    marginLeft: "10%", // Shift content when menu is open  


                }}>
                    {/* <Editor
                        ref={editorRef}
                        // value={text}
                        value={newDocument?.title}
                        
                        onTextChange={(e) => {
                            const newTitle = e.htmlValue; // Use textValue to avoid HTML tags
                            newSetDocument({ ...newDocument, title: newTitle });
                            setTitle(newTitle);
                        }}
                        // onTextChange={(e) => {setText(e.htmlValue); }}
                        headerTemplate={null} // Hide the default header in the Editor
                        style={{
                            height: '100vh',
                         
                        }}
                    /> */}

                    {loading ? (
                        <>
                            {/* You can also add a few small lines inside the skeleton like loading text lines */}
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <Skeleton width="100%" height={30} animation="wave" />
                                <Skeleton width="100%" height={60} animation="wave" />
                                <Skeleton width="100%" height={120} animation="wave" />
                                <Skeleton width="100%" height={40} animation="wave" />
                                <Skeleton width="100%" height={60} animation="wave" />
                                <Skeleton width="100%" height={30} animation="wave" />
                                <Skeleton width="100%" height={60} animation="wave" />
                                <Skeleton width="100%" height={120} animation="wave" />

                            </div>
                        </>) : (
                        <Editor
                            ref={editorRef}
                            style={{width: "100%", height: '70.5vh', marginLeft: "10%",// Adjust margin when menu is open
                        }}
                            headerTemplate={null}
                            placeholder="Type here..."
                            value={stripHtml(newDocument?.text) || editorValue}
                            onTextChange={(e) => {
                                const content = stripHtml(e.htmlValue);
                                setEditorValue(content);
                                setLoadingInner(true)
                            }}
                            className="w-[250px] sm:w-[350px] md:w-[450px]  lg:w-[600px]  xl:w-[700px] 2xl:w-[900px]" />
                    )}



                </div>
            </div>
        </div>
    );
}
