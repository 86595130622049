import config from "../../../libs/config";

export const pricingPlans = [
  {
    id: config.razorpay.planId.basic,
    name: "Free Plan",
    newPrice: "Free",
    requestDemoBtn: "Request a Demo",
    showRequestDemoButton: false,
    features: [
      "Generate 8k* characters per month",
      "Access to use cases",
      "Built in Grammar Checker",
      "Write in 3+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in premium plagiarism detection",
      "Access to writers community(upcoming)",
      "Download reports in PDF",
    ],
  },
  {
    // id_india: config.razorpay.planId.test, //test
    // id_international: config.razorpay.planId.test, //test
    id_india: config.razorpay.planId.indian_monthly, //live
    id_international: config.razorpay.planId.international_monthly, //live
    discount_Plan: config.razorpay.planId.discount_monthly,
    name: "Standard Plan",
    indianOldPrice: "1498",
    currency: "USD",
    interval: "M",
    price: "TWELVE_NINE_NINE",
    subName: "Monthly",
    inrprice: "ONE",
    isLtd: false,
    discount_indianOldPrice: "1082",
    indianPrice: "1082/month",
    discount_indianPrice: "1028/month",
    discount_internationPrice: "12.34",
    internationalOldPrice: "17.99",
    internationalPrice: "12.99/month",
    billed: "Billed Monthly",
    requestDemoBtn: "Request a Demo",
    mode: "MONTHLY",
    showRequestDemoButton: true,
    features: [
      "Generate 50 outputs per month for AI writer",
      "Access to 25+ use cases(upcoming)",
      "Write in 8+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in 50 outputs per month for premium plagiarism detection ",
      "Built in 50 outputs per month for premium grammar checker ",
      "Download reports in PDF",
      "Create your own custom use case(upcoming)",
      "Generate 15 outputs per month for Content Scheduler(upcoming) ",
      "Generate 15 outputs per month for SEO Optimisation(upcoming)",
      "Email and chat support(upcoming)",
      "Browser extensions(upcoming)",
    ],
  },
  {
    id_india: config.razorpay.planId.indian_yearly,
    id_international: config.razorpay.planId.indian_yearly,
    // id_international: config.razorpay.planId.international_yearly,
    discount_Plan: config.razorpay.planId.discount_yearly,
    name: "Pro Plan",
    indianOldPrice: "17976",
    discount_indianOldPrice: "10820",
    indianPrice: "10820/year",
    discount_indianPrice: "10280/year",
    discount_internationPrice: "123.49",
    internationalOldPrice: "179.99",
    internationalPrice: "129.99/year",
    currency: "USD",
    interval: "Y",
    price: "TWELVE_NINE_NINE_NINE",
    inrprice: "TWO",
    isLtd: false,
    subName: "Yearly",
    billed: "Billed Yearly",
    requestDemoBtn: "Subscribe",
    mode: "YEARLY",
    showRequestDemoButton: true,
    features: [
      "Generate 100 outputs per month for AI writer",
      "Access to 50+ use cases(upcoming)",
      "Write in 15+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in 100 outputs per month for premium plagiarism detection ",
      "Built in 100 outputs per month for premium grammar checker ",
      "Download reports in PDF",
      "Create your own custom use case(upcoming)",
      "Generate 30 outputs per month for Content Scheduler(upcoming) ",
      "Generate 30 outputs per month for SEO Optimisation(upcoming)",
      "Email and chat support(upcoming)",
      // "Access to referral community(upcoming)",
      "Browser extensions(upcoming)",
    ],
  },
];

export const pricingPlansLtd = [
  {
    isLtd: true,
    // id_india: config.razorpay.planId.test, //test
    // id_international: config.razorpay.planId.test, //test
    id_india: config.razorpay.planId.indian_lifetime_free_1, //live
    id_international: config.razorpay.planId.international_monthly, //live
    id_ltd: config.razorpay.planId.indian_lifetime_free_1,
    discount_id_ltd: config.razorpay.planId.discount_ltd_1,
    discount_Plan: config.razorpay.planId.discount_monthly,
    planName: "Standard Plan",
    nameLtd: "Lifetime Deal Plan",
    appSumoPrice: "79",
    appSumoPriceInd: "6568",

    indianOldPrice: "1498",
    discount_indianOldPrice: "1082",
    indianPrice: "1082/month",
    discount_indianPrice: "1028/month",
    discount_internationPrice: "12.34",
    indianLtdPrice: "4084",
    discount_indianLtdPrice: "3880",
    discount_internationLtdPrice: "46.55",
    internationalOldPrice: "17.99",
    internationalPrice: "12.99/month",
    internationalLtdPrice: "49",
    billed: "Billed Monthly",
    requestDemoBtn: "Request a Demo",
    mode: "lydOne",
    showRequestDemoButton: true,
    currency: "USD",
    interval: "Y",
    price: "FOUR_NINE",
    subName: "ONE",
    features: [
      "Generate unlimited characters per month",
      "Access to 25+ use cases(upcoming)",
      "Write in 8+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in premium plagiarism detection",
      "Access to writers community(upcoming)",
      "Download reports in PDF",
      "Create your own custom use case(upcoming)",
      "Content Scheduler(upcoming)",
      "SEO Optimisation(upcoming)",
      "Email and chat support(upcoming)",
      "Browser extensions(upcoming)",
    ],
    featuresLtd: [
      "Generate 50 outputs per month for AI writer",
      "Access to 25+ use cases(upcoming)",
      "Write in 8+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in 50 outputs per month for premium plagiarism detection ",
      "Built in 50 outputs per month for premium grammar checker ",
      "Download reports in PDF",
      "Create your own custom use case(upcoming)",
      "Generate 15 outputs per month for Content Scheduler(upcoming) ",
      "Generate 15 outputs per month for SEO Optimisation(upcoming)",
      "Email and chat support(upcoming)",
      "Browser extensions(upcoming)",
    ],
    appSumoFeaturesLtd: [
      " All features above included",
      "Al writer: Generate ~ 37,500 words/month (approx.)",
      "Editor: Make changes easily to your Al generated writeup",
      "Grammar Checker: Find & eliminate upto 25,000 grammatical errors in a month",
      "Plagiarism Checker: Check plagiarism and generate detailed report upto 40 times a month",
      "Generate 15 outputs per month for Content Scheduler (upcoming)",
      "Generate 15 outputs per month for SEO Optimisation (upcoming)",
      "Email and chat support (upcoming)",
      "Browser extensions (upcoming)",
      "Write in 8+ languages (upcoming)",
    ],
  },
  {
    isLtd: true,
    id_india: config.razorpay.planId.indian_lifetime_free_2,
    id_international: config.razorpay.planId.indian_yearly,
    // id_international: config.razorpay.planId.international_yearly,
    id_ltd: config.razorpay.planId.indian_lifetime_free_2,
    discount_id_ltd: config.razorpay.planId.discount_ltd_2,
    discount_Plan: config.razorpay.planId.discount_yearly,
    planName: "Pro Plan",
    nameLtd: "Lifetime Deal Plan",
    indianOldPrice: "17976",
    discount_indianOldPrice: "10820",
    indianPrice: "10820/year",
    appSumoPrice: "158",
    appSumoPriceInd: "13136",
    discount_indianPrice: "10280/year",
    discount_internationPrice: "123.43",
    indianLtdPrice: "8084",
    discount_indianLtdPrice: "7680",
    discount_internationLtdPrice: "93.10",
    internationalOldPrice: "179.99",
    internationalPrice: "129.99/year",
    internationalLtdPrice: "98",
    billed: "Billed Yearly",
    requestDemoBtn: "Subscribe",
    mode: "lydTwo",
    showRequestDemoButton: true,
    currency: "USD",
    interval: "Y",
    price: "NINE_EIGHT",
    subName: "TWO",

    featuresLtd: [
      "Generate 100 outputs per month for AI writer",
      "Access to 50+ use cases(upcoming)",
      "Write in 15+ languages(upcoming)",
      "Access premium tones(upcoming)",
      "Built in 100 outputs per month for premium plagiarism detection ",
      "Built in 100 outputs per month for premium grammar checker ",
      "Download reports in PDF",
      "Create your own custom use case(upcoming)",
      "Generate 30 outputs per month for Content Scheduler(upcoming) ",
      "Generate 30 outputs per month for SEO Optimisation(upcoming)",
      "Email and chat support(upcoming)",
      // "Access to referral community(upcoming)",
      "Browser extensions(upcoming)",
    ],

    appSumoFeaturesLtd: [
      " All features above included",
      "Al writer: Generate ~ 75,000 words/month (approx.)",
      "Editor: Make changes easily to your Al generated writeup",
      "Grammar Checker: Find & eliminate upto 50,000 grammatical errors in a month",
      "Plagiarism Checker: Check plagiarism and generate detailed report upto 80 times a month",
      "Generate 30 outputs per month for Content Scheduler (upcoming)",
      "Generate 30 outputs per month for SEO Optimisation (upcoming)",
      "Email and chat support (upcoming)",
      "Browser extensions (upcoming)",
      "Write in 8+ languages (upcoming)",
    ],
  },
];
