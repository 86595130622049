import imgcard from "../../../assets/Rectangle 38.png";
import profileImg from "../../../assets/smallImgProfile.svg";
import detailImg from "../../../assets/detailImage1.jpeg";

const cardsData = [
  {
    id: 1,
    imageUrl: imgcard,
    category: 'Technology',
    title: 'Demystify Your Writing: An Inside Look into the Future of Content Creation - Oyster',
    smallImage: profileImg,
    name: 'Tracey Wilson',
    date: 'August 20, 2022',
    briefDescription: {
      blogTitle: 'Demystify Your Writing An Inside Look into the Future of Content Creation Oyster',
      detailTitle: 'Step-by-step guide to choosing great font pairs',
      description: <>In the current digital age, content creation has become integral to our personal and professional
        lives. The increasing demand for high-quality content is ever-lasting, whether you are a
        professional writer, blogger, or digital marketer. Oyster hops in the picture for framing error-free,
        engaging content and making your work hassle-free. Content creation is quite challenging and
        time-consuming when a writer has to consistently produce content that has to be in a properly
        organized structure with an impeccable writing style and free of grammatical errors.<br /><br />
        It is time to introduce you to the next big thing in the industry of content creation, Oyster. It is not
        just a writing assistance tool but a game changer in the ecosystem of content. Oyster's key
        features include AI-powered suggestions, automated suggestions and corrections, and
        customization, and apart from digital assistance, it subconsciously helps the user by increasing
        productivity and reducing stress and anxiety. It gives the user essential time to unleash their
        inner creativity.<br /><br />
        At some point in time, every writer needs a few suggestions and recommendations for their
        content to cope with the needs of the market and how to implement the rapid changes in the
        industry with a quick solution. There are multiple writing assistance services available in the
        market, but Oyster is a one-step solution equipped with every tool that is essential for content
        creation, and it offers vast leverage to the writer from scratch to end (from grammar checking to
        content scheduling).
        <br /><br />
        The art of writing has seen many phases and has been more digitalized, conceptualized, and
        classified over time. All these are the core principles of writing assistance software. With a
        creative approach and a pinch of assistance from Oyster, a writer can pen down their
        imagination within no time with great ease and perfection every single time, and over time all the
        facilities will adapt to the ongoing trends of the market. With every new phase in the content
        creation industry, Oyster will be there with the same principles but a different approach.<br /></>,
      detailImage: detailImg,
      // profileImage: detailImg,
      date: 'Posted on 27th January 2022',
      publisher: 'Andrew Jonson',
      category: 'Startup',


    }
  },
  {
    id: 2,
    imageUrl: imgcard,
    category: 'Technology',
    title: 'Unveiling the Future of AI in Writing Assistance',
    smallImage: profileImg,
    name: 'Tracey Wilson',
    date: 'August 20, 2022',
    briefDescription: {
      blogTitle: 'Unveiling the Future of AI in Writing Assistance',
      detailTitle: 'Step-by-step guide to choosing great font pairs',
      description: <>In the ever-evolving landscape of technology, artificial intelligence (AI) has become an
        indispensable tool, reshaping various industries. One of the most fascinating applications of AI
        is in writing assistance, where it serves as a virtual co-pilot for content creators, offering a
        glimpse into the future of efficient and creative writing.<br /><br />
        AI writing assistance is no longer limited to basic grammar checks or spell corrections. The
        future promises a more sophisticated and intuitive approach, where AI algorithms understand
        context, tone, and even the nuances of various writing styles. This evolution will undoubtedly
        redefine the way we write and communicate.<br /><br />
        One of the key advancements on the horizon is the integration of AI in generating personalized
        content. Imagine an AI writing assistant who not only corrects errors but also understands your
        unique voice, preferences, and writing style. This level of personalization could revolutionize
        content creation, making it more authentic and tailored to individual needs.<br /><br />
        Furthermore, AI is poised to take on a more collaborative role in the writing process. Advanced
        AI algorithms will be capable of generating suggestions, providing creative inputs, and even
        coauthoring pieces alongside human writers. This collaborative synergy between human creativity
        and AI efficiency could result in content that transcends the limits of individual capabilities.
        Language translation is another domain where AI is making significant strides. In the future,
        AI writing assistants will seamlessly translate text, ensuring effective communication across
        linguistic barriers. This will be a game-changer for global collaboration and content accessibility,
        fostering a more connected and inclusive digital landscape.
        <br /><br />
        The future of AI in writing assistance also holds the promise of enhanced content optimization.
        AI algorithms will analyze data trends, user engagement metrics, and SEO parameters to recommend
        improvements for maximum impact. This proactive approach will empower writers to create content
        that not only resonates with their audience but also meets the ever-changing demands of the
        digital realm.<br /><br />
        However, with great power comes great responsibility. As AI becomes more integral to the writing
        process, ethical considerations must be at the forefront. Striking the right balance between
        automation and human creativity is essential to ensure that AI writing assistance remains a
        valuable tool without compromising the authenticity and uniqueness of human expression.<br /><br />
        In conclusion, the future of AI in writing assistance is a captivating journey into a realm where
        personalization, collaboration, and optimization converge. As AI continues to evolve, writers can
        look forward to a landscape where their creativity is amplified, their workflow is streamlined,
        and the possibilities for innovative content are boundless. The future is not just about
        correcting grammar; it's about co-creating a new era of writing excellence.</>,
      detailImage: detailImg,
      // profileImage: detailImg,
      date: 'Posted on 27th January 2022',
      publisher: 'Andrew Jonson',
      category: 'Startup',


    }
  },

];

export default cardsData;
