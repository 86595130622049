import { Img } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import newDocumentIcon from "../assets/Editor/newDocumentIcon.png"
import uploadIcon from "../assets/Editor/uploadIcon.png"
import DownloadFileIcon from "../assets/Editor/DownloadFileIcon.png"
import printIcon from "../assets/Editor/printIcon.png"
import { toast } from "react-toastify";
import axios from "../libs/axios";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";

import {
    UserContext,
    UserDispatchContext,
    useUpgradeModal,
  } from "./context/user";
  
const Leftpane = ({ toolbarRef ,setMenuOpen,menuOpen, currentDocument  }) => {
    const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);
    const { setshowupgrade } = useUpgradeModal();
    const [documents, setDocuments] = useState([]);
    const navigate = useNavigate();
    const [isMobile] = useMediaQuery("(max-width: 900px)");

    const Items = [
        { name: "Home", path: "/dashboard" },
        { name: "Documents", path: "" }
    ];

    const convertHtmlToRtf = (html) => {
        // RTF header
        let rtf = "{\\rtf1\\ansi\\deff0";
        
        // Font table
        rtf += "{\\fonttbl{\\f0\\fswiss\\fcharset0 Arial;}}";
        
        // Create temporary element to parse HTML
        const temp = document.createElement('div');
        temp.innerHTML = html;
        
        // Process the content
        const processNode = (node) => {
            let result = '';
            
            if (node.nodeType === 3) { // Text node
                result += node.textContent.replace(/[\\{}]/g, '\\$&');
            } else if (node.nodeType === 1) { // Element node
                const tag = node.tagName.toLowerCase();
                
                // Handle different HTML tags
                if (tag === 'em' || tag === 'i') {
                    result += '{\\i ';
                } else if (tag === 'strong' || tag === 'b') {
                    result += '{\\b ';
                } else if (tag === 'p') {
                    result += '\\par ';
                }
                
                // Process child nodes
                Array.from(node.childNodes).forEach(child => {
                    result += processNode(child);
                });
                
                // Close formatting if needed
                if (tag === 'em' || tag === 'i' || tag === 'strong' || tag === 'b') {
                    result += '}';
                }
            }
            
            return result;
        };
        
        // Process the content and add to RTF
        rtf += processNode(temp);
        
        // Close RTF document
        rtf += "}";
        
        return rtf;
    };

    const handleDownload = (e) => {
        e.preventDefault();
        try {
            if (!currentDocument?.text) {
                toast.error("No document content to download");
                return;
            }

            // Convert HTML to RTF
            const rtfContent = convertHtmlToRtf(currentDocument.text);
            
            // Create blob with RTF content
            const blob = new Blob([rtfContent], { type: 'application/rtf' });
            
            // Create download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            
            // Set filename with .rtf extension
            const filename = `${currentDocument.title || 'document'}.rtf`;
            link.setAttribute('download', filename);
            
            // Trigger download
            document.body.appendChild(link);
            link.click();
            
            // Cleanup
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success("Document downloaded successfully!");
        } catch (error) {
            console.error("Download failed:", error);
            toast.error("Failed to download document");
        }
    };

    const handlePrint = (e) => {
        
        e.preventDefault();
        try {
            if (!currentDocument?.text) {
                toast.error("No document content to print");
                return;
            }

            // Create a temporary container for the document content
            const printContainer = document.createElement('div');
            printContainer.innerHTML = currentDocument.text;
            printContainer.style.display = 'none';
            document.body.appendChild(printContainer);

            // Print the document
            window.print();

            // Cleanup
            document.body.removeChild(printContainer);
            
            toast.success("Document sent to printer!");
        } catch (error) {
            console.error("Print failed:", error);
            toast.error("Failed to print document");
        }
    };


    const newDocument = async () => {
       
        try {
          const newDocRes = await axios.post("/api/document");
          if (newDocRes?.data?.notSubscribed) {
            setshowupgrade(true);
            return;
          }
    
          setDocuments(
            documents.length > 0
              ? [...documents, newDocRes.data.data]
              : [newDocRes.data.data]
          );
          navigate(`/editor/${newDocRes.data.data._id}`);
        } catch (error) {
          console.log(error);
        }
      };

    const documentSubItems = [
        // {
        //     name: "New Document",
        //     icon: <Img
        //         style={{ height: "16px", width: "16px", marginTop: "2px" }}
        //         src={newDocumentIcon} />,
        //     onClick : newDocument
        // },
        // {
        //     name: "Upload File", icon: <Img
        //         style={{ height: "16px", width: "16px", marginTop: "2px" }}
        //         src={uploadIcon}

        //     />, path: "/upload-file"
        // },
        {
            name: "Download", icon: <Img
                style={{ height: "20px", width: "20px", marginTop: "2px" }}
                src={DownloadFileIcon}

            />, path: "/download",
            onClick: handleDownload
        },
        {
            name: "Print", icon: <Img
                style={{ height: "20px", width: "20px", marginTop: "2px" }}
                src={printIcon}

            />, 
            onClick: (e) => {
                setMenuOpen(false);  
                setTimeout(() => {    
                    handlePrint(e);
                }, 100);
            }
        }
    ];

    const toggleDocuments = () => {
        setIsDocumentsOpen(!isDocumentsOpen);
    };



    return (
        <div className={`absolute h-[92.5vh] ${isMobile ? 'ml-0 w-[100%] sm:[50%] '  : ' w-[200px]'} mt-4 bg-white`} style={{boxShadow:" rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}}>
            {Items.map((item) => (
                <>
                   <Link to={item?.path}>  <div key={item.name} style={{ display: "flex",gap:"5%" , alignItems: "center", padding: "2%",paddingLeft:"5%", cursor: "pointer" }} onClick={item.name === "Documents" ? toggleDocuments : null}>
                        <Link to={item?.path}>
                            <div
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    color: "#040404",
                                    marginTop: "10%",
                                }}

                            >
                                {item.name}

                            </div>
                        </Link>
                        {item.name === "Documents" && (
                            <>
                                {isDocumentsOpen ? <svg style={{ marginTop: "4%" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                                    <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                </svg>
                                    : <svg style={{ marginTop: "4%" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                                    </svg>
                                }
                            </>
                        )}

                    </div>
                    </Link>
                    {/* <hr style={{ width: "90%", marginLeft: "3%" }} /> */}

                    {item.name === "Documents" && isDocumentsOpen && (
                        <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                            {documentSubItems.map((subItem) => (
                                <Link to={subItem.path} key={subItem.name} onClick={subItem.onClick}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            color: "#040404",
                                            marginTop: "10px"
                                        }}
                                    >
                                        <span style={{ marginRight: "8px" }}>{subItem.icon}</span>
                                        {subItem.name}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    )}
                </>
            ))}
            {/* <div ref={toolbarRef} className="mt-[20px] h-full d-flex"></div> */}
        </div>
    );
};

export default Leftpane;