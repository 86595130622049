import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./components/Loder/Loder";

ReactModal.setAppElement("#root");
const root = ReactDOM.createRoot(document.getElementById("root"));

function RootComponent() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? <Loader /> : <App />}
    </BrowserRouter>
  );
}

root.render(<RootComponent />);
