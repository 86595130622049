// FormOne.jsx
import React, { useState } from 'react';
import './FormOne.css';

const FormOne = ({ onNextButtonClick, handleDoneButtonClick }) => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-form-head">Let's Personalize Your<br /><span className="highlight">Writing</span> Experience</h2>
        <h3 className="login-form-head-2">Start by telling us your writing needs.</h3>
        <h1 className="login-form-head-3">Most of my writing is for:</h1>
        
        <div className="login-buttons-container">
          {['School', 'Work', 'Blogger'].map((label, index) => (
            <button
              key={index}
              className={`button-login ${activeButton === index + 1 ? 'active' : ''}`}
              onClick={() => handleButtonClick(index + 1)}
            >
              {label}
            </button>
          ))}
        </div>

        <div className="login-buttons-container-2">
          <button onClick={handleDoneButtonClick} className="button-later-form">
            Later
          </button>
          <button className="button-next-form" onClick={onNextButtonClick}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormOne;
