// FormTwo.jsx
import React from 'react';
import './FormTwo.css';

const FormTwo = ({ onDoneButtonClick }) => {
  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-form-head">Tell us about your team</h2>
        <form className="login-form-fields">
          {[
            {
              label: 'My primary function is',
              id: 'selectOption1',
              options: ['Marketing', 'Sales', 'Graphic Design', 'Operations', 'Legal', 'Quality', 'IT', 'Software Development', 'Production/Manufacturing', 'Advertising', 'Business Development', 'Human Resources', 'Administration', 'Content Writer', 'Finance'],
            },
            {
              label: 'My Company size is',
              id: 'selectOption2',
              options: ['1-49', '50-499', '500-999', '1000+'],
            },
            {
              label: 'My role is',
              id: 'selectOption3',
              options: ['Individual Contributor', 'Manager', 'Senior Manager', 'Vice President', 'Director', 'Head', 'CEO', 'CFO', 'CXO'],
            },
          ].map((selectData, index) => (
            <div key={index}>
              <label className="label-dropdown" htmlFor={selectData.id}>
                {selectData.label}
              </label>
              <select className="dropdown-form" id={selectData.id} name={selectData.id} defaultValue="">
                <option value="" disabled>
                  Select an option
                </option>
                {selectData.options.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </form>
        
        <div className="login-buttons-container-2">
          <button className="button-later-form" onClick={onDoneButtonClick}>Later</button>
          <button className="button-next-form" onClick={onDoneButtonClick}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default FormTwo;
