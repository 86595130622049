export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  // {
  //   title: "Team",
  //   url: "/teams",
  // },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "Contact us",
    url: "/Contact-Us",
  },
  {
    title: "Login",
    url: "/auth",
  },
];
