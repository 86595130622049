import React from "react";
import arrow from "../../assets/Suggestions/-_.png";
import greenDot from "../../assets/Suggestions/green-dot.svg";
// import yellowDot from "../../assets/Suggestions/yellow-dot.svg";
// import orangeDot from "../../assets/Suggestions/orange-dot.svg";
import { replaceWord } from "../../libs/utility";
import Group from "../../assets/Editor/Group.png";

const SuggestionCard = ({
  result,
  message,
  shortMessage,
  correctWord: initialCorrectWord,
  precedingWords, // New prop for preceding words
  errorWord,
  document,
  setDocument,
  updateDocumentContent,
  checkGrammer,
  //  customColor,
}) => {
  //  const renderDotImg = () => {
  //   switch (customColor) {
  //    case "customGreen":
  //     return greenDot;
  //    case "customYellow":
  //     return yellowDot;
  //    case "customOrange":
  //     return orangeDot;
  //    default:
  //     return "";
  //   }
  //  };

  //  const fixWord = async (data) => {
  //   if (!result || !correctWord || !document) return;
  //   const text = document.text;

  //   // Create a variable to store the updated word
  //   let updatedWordValue = " ";

  //   correctWord?.forEach((newWord) => {
  //    if (newWord?.value === data) {
  //     const finalword = newWord?.value;
  //     updatedWordValue = finalword; // Update the variable
  //    }
  //   });

  //   if (updatedWordValue !== " ") {
  //    const correctString = await replaceWord(
  //     text,
  //     updatedWordValue,
  //     result?.offset,
  //     result?.length
  //    );

  //    // TODO save thing is not happening on first go. need to click twice to save/changes to be reflected !
  //    setDocument({ ...document, text: correctString });
  const [correctWord, setCorrectWord] = React.useState(initialCorrectWord);
  const fixWord = async (data) => {
    if (!result || !correctWord || !document) return;
    const text = document.text;

    let updatedWordValue = " ";

    correctWord?.forEach((newWord) => {
      if (newWord?.value === data) {
        const finalword = newWord?.value;
        updatedWordValue = finalword; // Update the variable
      }
    });

    if (updatedWordValue !== " ") {
      const correctString = await replaceWord(
        text,
        updatedWordValue,
        result?.offset,
        result?.length
      );

      // TODO save thing is not happening on first go. need to click twice to save/changes to be reflected !
      setDocument({ ...document, text: correctString });
      saveAndCheck(correctString);
      // Reset the state if needed
    }
  };

  const saveAndCheck = async (correctString) => {
    if (updateDocumentContent != null)
      await updateDocumentContent(correctString);
    if (checkGrammer != null) await checkGrammer();
  };

  const handleDismiss = (valueToRemove) => {
    // Filter out the object with the specified value
    const updatedWords = correctWord.filter(
      (item) => item.value !== valueToRemove
    );
    setCorrectWord(updatedWords); // Update the state
  };
  const stripHtml = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };
// console.log(correctWord)
  // console.log(shortMessage)
  return (
    <>
    {/* {correctWord == null ? "No correction": ``} */}
      {(correctWord || correctWord === "") && (
        <div
          onClick={fixWord}
          className={`my-1
            
                    px-4 pt-3
                    rounded-xl
                    font-inter
                     border-[1.5px] border-[#767676]/80
                    
										cursor-pointer
                    `}
        >
          <div className="flex text-md text-[#5E5E5E] font-[300]">
            {correctWord == null ? "" : ` ${shortMessage}` }
            {/* <img className="h-fit mt-2 mr-2" src={greenDot} alt="bullet" /> */}
            {/* {shortMessage} */}
          </div>
          <div className=" my-2">
            <div className="">
              {correctWord.map((obj) => {
                return (
                  <>
                    <div className=" flex gap-1">
                      <div className="text-[16px]    text-[#424242]">
                        <span className="" key={obj.id}>
                          ....{stripHtml(precedingWords)} <span className="text-[14px] inline text-[#3A3AF4] font-[600]">{obj.value}</span>
                        </span>
                        
                      </div>
                      <div className={` rounded-[0.2rem]  gap-2`}></div>
                    </div>

                    <div
                      className="mt-2  items-end pb-3"
                      style={{ display: "flex", gap: "4%" }}
                    >
                      <button
                        className="bg-[#3A3AF4] text-white text-[12px] shadow-sm"
                        style={{ width: "60px", height: "22px" }}
                        onClick={() => fixWord(obj.value)}
                      >
                        Accept
                      </button>
                      <button
                        className=" text-[000000]  text-[12px] bg-[#F6F3F3] shadow-sm"
                        style={{
                          width: "60px",
                          height: "22px",
                          boxShadow: "0 0 0px 0px #000000, 0 0 4px 4px #f3f4f6",
                        }}
                        onClick={() => handleDismiss(obj.value)}
                      >
                        Dismiss
                      </button>
                      <img src={Group} alt="" className="h-[16px] w-[16px]" />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          
          {/* <div
            className="w-[98%] text-[12px] text-[#818181] font-[300]
                      tracking-wide"
          >
            {message}
          </div> */}
          {/* <img className="absolute right-2 bottom-2" src={bin} alt="bin" /> */}
        </div>
      )}
    </>
  );
};

export default SuggestionCard;